import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { CtaCard } from '@/components/common/CtaCard';
import { Icon } from '@/components/common/Icon';
import { Image } from '@/components/common/image';
import { HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalHeading,
  ModalTrigger,
} from '@/components/common/Modal';
import { TabView } from '@/components/common/TabView';
import {
  ConditionalAnimateWrapper,
  PortableText,
  VisuallyHidden,
} from '@/components/common/utils';
import { WistiaEmbed } from '@/components/common/WistiaEmbed';
import { Section } from '@/components/layout/Section';
import { getButtonComponentThemeSolid } from '@/lib/colourUtils';
import { blockUrlWithAudience } from '@/lib/utils';
import { bodyLarge } from '@/styles/typography';

import type {
  SanityAudienceType,
  SanityFeatureExplainerType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface FeatureExplainerProps
  extends SanityFeatureExplainerType,
    BlockWithAudience {}

type Tab = SanityFeatureExplainerType['tabs'][0];

const FeatureExplainer = ({
  tag,
  headingCaption,
  heading,
  subheading,
  tabs,
  animate,
  headingAlignment,
  backgroundColour,
  pageAudience,
  isAudienceSwitcherEnabled,
}: FeatureExplainerProps) => {
  const tabData = tabs?.map((tab: Tab) => {
    return {
      label: tab.label,
      variant: 'standard',
      content: (
        <Content
          image={tab.image}
          videoPopup={tab.wistiaPopover}
          columnContent={tab.columnContent}
          cardContent={tab.ctaCard.ctaCard}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
        />
      ),
      trackingTag: tab.tabTrackingTag,
    };
  });

  const captionHeadingDescription = {
    caption: headingCaption,
    heading,
    headingAlignment,
    description: subheading,
  };

  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding
      className="feature-explainer"
    >
      <HeadingLevel>
        <ConditionalAnimateWrapper animate={animate}>
          {tag?.tagText && (
            <Badge
              bgColour={tag?.tagColour}
              margin="0 0 var(--spacing-xxx-small) 0"
            >
              {tag.tagText}
            </Badge>
          )}
          <StyledCaptionHeadingDescription
            {...captionHeadingDescription}
            mobileAlignment="center"
            desktopAlignment={headingAlignment}
          />
        </ConditionalAnimateWrapper>
        <ConditionalAnimateWrapper animate={animate}>
          <FeatureExplainerTabView tabs={tabData} />
        </ConditionalAnimateWrapper>
      </HeadingLevel>
    </Section>
  );
};

const FeatureExplainerTabView = styled(TabView)``;

const FeatureExplainerColumns = ({ columnContent }) => {
  const columns = Object.values(columnContent);

  return (
    <FeatureExplainerColumnsContainer>
      {columns.map((column: any) => (
        <FeatureExplainerColumn key={column.heading}>
          <FeatureExplainerColumnTitle size="subheading-sm">
            {column.heading}
          </FeatureExplainerColumnTitle>
          <FeatureExplainerColumnText size="body-lg">
            {column.body}
          </FeatureExplainerColumnText>
        </FeatureExplainerColumn>
      ))}
    </FeatureExplainerColumnsContainer>
  );
};

const FeatureExplainerColumnsContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-large);

  ${screen.sm} {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: baseline;
  }
`;

const FeatureExplainerColumnTitle = styled(Paragraph)`
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-xxx-small);
`;

const FeatureExplainerColumn = styled.div`
  max-width: 400px;

  text-align: center;
  margin: 0 auto;

  ${screen.sm} {
    text-align: start;
  }
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-x-large);
`;

const FeatureExplainerColumnText = styled(Paragraph)``;

type ContentProps = {
  image: Tab['image'];
  columnContent: Tab['columnContent'];
  cardContent: any;
  videoPopup: Tab['wistiaPopover'];
  pageAudience: SanityAudienceType;
  isAudienceSwitcherEnabled: boolean;
};

const Content = ({
  image,
  columnContent,
  cardContent,
  videoPopup,
  pageAudience,
  isAudienceSwitcherEnabled,
}: ContentProps) => {
  const hasPopup = !!videoPopup?.videoId;

  return (
    <FeatureExplainerTabContentLayout>
      <FeatureExplainerImageContainer image={image}>
        <Image asset={image} objectFit="cover" alt="" />
        {hasPopup && (
          <>
            <VideoPopup>
              <Modal>
                <ModalTrigger>
                  <PlayButton
                    aria-hidden="true"
                    className={
                      videoPopup.trackingTag?.blockName &&
                      `tracking-${videoPopup.trackingTag.blockName}__${videoPopup.trackingTag.blockAction}`
                    }
                  >
                    <Icon icon="Play" size="32px" aria-hidden="true" />
                  </PlayButton>
                </ModalTrigger>
                <ModalContent className="Modal">
                  <ModalClose />

                  <ModalHeading>
                    <VisuallyHidden>{videoPopup.videoTitle}</VisuallyHidden>
                  </ModalHeading>
                  <WistiaEmbed videoId={videoPopup.videoId} />
                </ModalContent>
              </Modal>
            </VideoPopup>
            <VideoContent>
              {!!videoPopup.videoTitle && (
                <VideoText>{videoPopup.videoTitle}</VideoText>
              )}
              {!!videoPopup.videoLength && (
                <VideoLength hasTitle={!!videoPopup.videoTitle}>
                  {videoPopup.videoLength}
                </VideoLength>
              )}
            </VideoContent>
          </>
        )}
      </FeatureExplainerImageContainer>
      <FeatureExplainerColumns columnContent={columnContent} />
      <FeatureExplainerCTACardContainer>
        <CtaCard
          heading={cardContent.heading}
          body={
            <CTACardBody>
              <BulletPoints>
                <PortableText
                  value={cardContent.body}
                  audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
                />
              </BulletPoints>
            </CTACardBody>
          }
          actions={cardContent.actions?.map((action) => (
            <Button
              key={action.buttonLabel}
              iconPosition="right"
              href={blockUrlWithAudience(
                action.buttonLink,
                action.audienceRef,
                pageAudience,
                action.noAudienceToggle,
                isAudienceSwitcherEnabled,
              )}
              variant={getButtonComponentThemeSolid(
                cardContent.backgroundColour,
              )}
              aria-label={action.buttonAccessibleLabel}
              className={
                action?.trackingTag?.blockName &&
                `tracking-${action.trackingTag.blockName}__${action.trackingTag.blockAction}`
              }
            >
              {action.buttonLabel}
            </Button>
          ))}
        />
      </FeatureExplainerCTACardContainer>
    </FeatureExplainerTabContentLayout>
  );
};

const FeatureExplainerTabContentLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: min-content max-content;
  gap: var(--spacing-medium);

  ${screen.md} {
    /* grid-template-columns: 2fr 1fr; */
    gap: var(--spacing-large);
  }
`;

const FeatureExplainerImageContainer = styled.div<{ image }>`
  display: flex;
  position: relative;
  border-radius: var(--radius-l);
  overflow: hidden;
  min-height: 200px;
  grid-column: 1 / -1;

  > img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  ${screen.sm} {
    display: block;
    min-height: 320px;
    height: 100%;
  }
  > img {
    height: 100%;
  }

  ${screen.md} {
    grid-column: 1/2;
    min-height: 200px;
  }
`;

const VideoPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000031;

  > span {
    width: 100%;
    height: 100%;
  }
`;

const PlayButton = styled.span`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;

  svg {
    pointer-events: none;
    color: transparent;
    path {
      fill: var(--surface-subtle);
    }
  }
`;

const VideoContent = styled(Paragraph)`
  ${bodyLarge}
  display: none;
  position: absolute;
  bottom: var(--spacing-300);
  left: var(--spacing-300);
  margin-right: var(--spacing-300);
  background-color: #000000aa;
  backdrop-filter: blur(3px);
  padding: var(--spacing-xx-small) var(--spacing-small);
  border-radius: var(--spacing-500);

  ${screen.sm} {
    display: flex;
  }
`;

const VideoText = styled.span`
  color: var(--text-inverse-strong);
  font-weight: var(--font-weight-semibold);
`;

const VideoLength = styled.span<{ hasTitle: boolean }>`
  color: var(--text-inverse-strong);
  position: relative;
  align-self: center;

  ${(props) =>
    props.hasTitle &&
    css`
      padding-left: var(--spacing-small);
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        height: 4px;
        width: 4px;
        background-color: var(--surface-warm-base);
        border-radius: 50%;
      }
    `}
`;

const FeatureExplainerCTACardContainer = styled.div`
  grid-column: 1 / -1;
  ${screen.md} {
    grid-row: 1;
    grid-column: 2 / 3;
  }
`;

const CTACardBody = styled.div`
  margin-top: var(--spacing-x-small);
`;

export { FeatureExplainer };
